import { Box, ChakraProvider, Flex, Image, Text } from '@chakra-ui/react';
import { Button, Modal } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { PlayState, Timeline, Tween } from 'react-gsap';
import React, { useEffect, useState } from 'react';
import { TBoard, useTopBoardsQuery } from '../generated/graphql';

import Logo from '../assets/images/common/logo.png';
import classNames from 'classnames';
import { showRightSidebar } from '../redux/actions';
import { useRedux } from '../hooks';

export interface NotificationItem {
    id: number;
    text: string;
    subText: string;
    icon?: string;
    avatar?: string;
    bgColor?: string;
}

export interface ProfileMenuItem {
    label: string;
    icon: string;
    badge?: { variant: string; text: string };
    redirectTo: string;
}

interface TopbarProps {
    hideLogo?: boolean;
    navCssClasses?: string;
    openLeftMenuCallBack?: () => void;
    topbarDark?: boolean;
}

const Topbar = ({ hideLogo, navCssClasses, openLeftMenuCallBack, topbarDark }: TopbarProps) => {
    const { dispatch } = useRedux();
    const [selectedMenu, setSelectedMenu] = useState<string>('회사소개');
    const currentLocation = useLocation()?.pathname;
    const [rightModal, setRightModal] = useState<boolean>(false);
    const [hoverYn, setHoverYn] = useState<boolean>(false);
    const { data } = useTopBoardsQuery();
    const toggle = () => {
        setRightModal(!rightModal);
    };
    const handleRightSideMenu = () => {
        dispatch(showRightSidebar());
    };

    useEffect(() => {
        let currentLocationNm = '';
        if (currentLocation === '/' || currentLocation === '/main') currentLocationNm = '회사소개';
        if (currentLocation === '/news/NEWS') currentLocationNm = '미디어 뉴스';
        if (currentLocation === '/news/PRESS') currentLocationNm = '보도자료';
        if (currentLocation.indexOf('/newsDetail') !== -1) currentLocationNm = '뉴스상세';
        if (currentLocation === '/employee_benefits') currentLocationNm = '복지';
        if (currentLocation === '/employment/NOTICE') currentLocationNm = 'NOTICE';
        if (currentLocation === '/employment/PROCESS') currentLocationNm = 'PROCESS';
        if (currentLocation === '/employment/Q&A') currentLocationNm = 'Q&A';
        if (currentLocation.indexOf('/employmentDetail') !== -1) currentLocationNm = '채용';
        setSelectedMenu(currentLocationNm);
    }, [currentLocation]);

    useEffect(() => {
        setHoverYn(false);
    }, [selectedMenu]);
    return (
        <React.Fragment>
            <Flex
                className="header"
                onMouseOver={() => {
                    if (window.innerWidth > 1024) setHoverYn(true);
                }}
                onMouseOut={() => {
                    if (window.innerWidth > 1024) setHoverYn(false);
                }}
                p={0}
                alignItems={'center'}
            >
                <div className="header-nav">
                    <div className="header-logo">
                        <a href="/">
                            <img src={Logo} />
                        </a>
                    </div>
                    <Flex className="header-menu" justifyContent={'end'}>
                        <ul>
                            <li className={selectedMenu === '회사소개' ? 'active' : ''}>
                                <Link to="/main">COMPANY</Link>
                            </li>
                            <li>
                                <Link to="/news/ALL">
                                    <Text
                                        _hover={{
                                            fontWeight: '700 !important',
                                            color: '#4a4d55 !important',
                                        }}
                                        fontWeight={
                                            selectedMenu === '보도자료' ||
                                            selectedMenu === '미디어 뉴스' ||
                                            selectedMenu === '뉴스상세'
                                                ? '700 !important'
                                                : '500 !important'
                                        }
                                        color={
                                            selectedMenu === '보도자료' ||
                                            selectedMenu === '미디어 뉴스' ||
                                            selectedMenu === '뉴스상세'
                                                ? '#4a4d55 !important'
                                                : '#8c919f !important'
                                        }
                                    >
                                        PRESS
                                    </Text>
                                </Link>
                                {hoverYn && (
                                    <Timeline
                                        playState={hoverYn ? PlayState.restart : PlayState.stop}
                                        target={
                                            <Flex className="header-submenu1" flexDir={'column'} zIndex={1401}>
                                                <Box className={`header-submenu-item`}>
                                                    <Link to="/news/NEWS">
                                                        <Text
                                                            fontWeight={
                                                                selectedMenu === '미디어 뉴스'
                                                                    ? '700 !important'
                                                                    : '500 !important'
                                                            }
                                                            color={
                                                                selectedMenu === '미디어 뉴스'
                                                                    ? '#4a4d55 !important'
                                                                    : '#8c919f !important'
                                                            }
                                                            _hover={{
                                                                color: '#4a4d55 !important',
                                                                fontWeight: '700 !important',
                                                            }}
                                                        >
                                                            미디어 뉴스
                                                        </Text>
                                                    </Link>
                                                </Box>
                                                <Box className={`header-submenu-item`}>
                                                    <Link to="/news/PRESS">
                                                        <Text
                                                            fontWeight={
                                                                selectedMenu === '보도자료'
                                                                    ? '700 !important'
                                                                    : '500 !important'
                                                            }
                                                            color={
                                                                selectedMenu === '보도자료'
                                                                    ? '#4a4d55 !important'
                                                                    : '#8c919f !important'
                                                            }
                                                            _hover={{
                                                                color: '#4a4d55 !important',
                                                                fontWeight: '700 !important',
                                                            }}
                                                        >
                                                            보도자료
                                                        </Text>
                                                    </Link>
                                                </Box>
                                            </Flex>
                                        }
                                    >
                                        <Tween from={{ y: -12 }} to={{ y: 0 }} duration={0.5} />
                                    </Timeline>
                                )}
                            </li>
                            <li className={selectedMenu === '복지' ? 'active' : ''}>
                                <Link to="/employee_benefits">BENEFIT</Link>
                            </li>
                            <li>
                                <Link to="/employment/NOTICE">
                                    <Text
                                        _hover={{
                                            fontWeight: '700 !important',
                                            color: '#4a4d55 !important',
                                        }}
                                        fontWeight={
                                            selectedMenu === 'NOTICE' ||
                                            selectedMenu === 'PROCESS' ||
                                            selectedMenu === 'Q&A'
                                                ? '700 !important'
                                                : '500 !important'
                                        }
                                        color={
                                            selectedMenu === 'NOTICE' ||
                                            selectedMenu === 'PROCESS' ||
                                            selectedMenu === 'Q&A'
                                                ? '#4a4d55 !important'
                                                : '#8c919f !important'
                                        }
                                    >
                                        RECRUIT
                                    </Text>
                                </Link>
                                {hoverYn && (
                                    <Timeline
                                        playState={hoverYn ? PlayState.restart : PlayState.stop}
                                        target={
                                            <Flex className="header-submenu2" flexDir={'column'} zIndex={1401}>
                                                <Box>
                                                    <Link to="/employment/NOTICE" className={`header-submenu-item`}>
                                                        <Text
                                                            fontWeight={
                                                                selectedMenu === 'NOTICE'
                                                                    ? '700 !important'
                                                                    : '500 !important'
                                                            }
                                                            color={
                                                                selectedMenu === 'NOTICE'
                                                                    ? '#4a4d55 !important'
                                                                    : '#8c919f !important'
                                                            }
                                                            _hover={{
                                                                color: '#4a4d55 !important',
                                                                fontWeight: '700 !important',
                                                            }}
                                                        >
                                                            NOTICE
                                                        </Text>
                                                    </Link>
                                                </Box>
                                                <Box>
                                                    <Link to="/employment/PROCESS" className={`header-submenu-item`}>
                                                        <Text
                                                            fontWeight={
                                                                selectedMenu === 'PROCESS'
                                                                    ? '700 !important'
                                                                    : '500 !important'
                                                            }
                                                            color={
                                                                selectedMenu === 'PROCESS'
                                                                    ? '#4a4d55 !important'
                                                                    : '#8c919f !important'
                                                            }
                                                            _hover={{
                                                                color: '#4a4d55 !important',
                                                                fontWeight: '700 !important',
                                                            }}
                                                        >
                                                            PROCESS
                                                        </Text>
                                                    </Link>
                                                </Box>
                                                <Box>
                                                    <Link to="/employment/Q&A" className={`header-submenu-item`}>
                                                        <Text
                                                            fontWeight={
                                                                selectedMenu === 'Q&A'
                                                                    ? '700 !important'
                                                                    : '500 !important'
                                                            }
                                                            color={
                                                                selectedMenu === 'Q&A'
                                                                    ? '#4a4d55 !important'
                                                                    : '#8c919f !important'
                                                            }
                                                            _hover={{
                                                                color: '#4a4d55 !important',
                                                                fontWeight: '700 !important',
                                                            }}
                                                        >
                                                            Q&A
                                                        </Text>
                                                    </Link>
                                                </Box>
                                            </Flex>
                                        }
                                    >
                                        <Tween from={{ y: -12 }} to={{ y: 0 }} duration={0.5} />
                                    </Timeline>
                                )}
                            </li>
                            {/* <li><a href="bbs.html">보도자료</a></li>
                            <li><a href="welfare.html">복지</a></li>
                            <li><a href="recruit01.html">채용</a></li> */}
                        </ul>
                    </Flex>
                    <button
                        type="button"
                        className="menu_icon"
                        onClick={() => {
                            handleRightSideMenu();
                        }}
                    ></button>
                    <Modal show={rightModal} onHide={toggle} dialogClassName={'modal-right'}>
                        <Modal.Header onHide={toggle} closeButton />
                        <Modal.Body>
                            <h5>Text in a modal</h5>
                            <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula.</p>
                        </Modal.Body>
                    </Modal>
                </div>
                {hoverYn && (
                    <ChakraProvider>
                        <Box
                            backdropFilter="blur(80px)"
                            backgroundColor={'rgba(19, 19, 19, 0.5)'}
                            display={['none', 'none', 'none', 'flex']}
                            width="100vw"
                            height="100dvh"
                            position="fixed"
                            left="0"
                            top="68px"
                            zIndex="1400"
                            justifyContent="center"
                            alignItems="center"
                            overflow="auto"
                            overscrollBehaviorY="none"
                            onMouseOver={(event: any) => {
                                event.stopPropagation();
                                if (window.innerWidth > 1024) setHoverYn(false);
                            }}
                            // onMouseOver={() => setHoverYn(true)}
                            // onMouseOut={}
                        >
                            <Timeline
                                playState={hoverYn ? PlayState.restart : PlayState.stop}
                                target={
                                    <Box
                                        width={' 100%'}
                                        backgroundColor={'#ffffff'}
                                        h={'322px'}
                                        boxShadow="0 2px 5px rgba(0, 0, 0, 0.1)"
                                        display={['none', 'none', 'none', 'block']}
                                        position={'fixed'}
                                        top={'0'}
                                        transition={'opacity 0.3s ease'}
                                    >
                                        <Flex
                                            className="news"
                                            flexDir={'column'}
                                            onMouseOver={(event: any) => {
                                                event.stopPropagation();
                                                if (window.innerWidth > 1024) setHoverYn(true);
                                            }}
                                        >
                                            {data?.topBoards?.boards?.map((item: TBoard, index: number) => {
                                                const { board_id, boadr_thumbnail_path, title } = item;
                                                return (
                                                    <>
                                                        {index < 2 && (
                                                            <Link to={`/newsDetail/${board_id}`} key={board_id}>
                                                                <Flex
                                                                    w={'448px'}
                                                                    h={'125px'}
                                                                    mt={'30px'}
                                                                    cursor={'pointer'}
                                                                    _hover={{
                                                                        transform: 'scale(1.05)',
                                                                    }}
                                                                >
                                                                    {/* <Image
                                                                        src={boadr_thumbnail_path || ''}
                                                                        w={'200px'}
                                                                        h={'125px'}
                                                                        borderRadius={'10px'}
                                                                    /> */}
                                                                    <Box>
                                                                        <Box
                                                                            w={'200px'}
                                                                            h={'125px'}
                                                                            position="relative"
                                                                            overflow={'hidden'}
                                                                            borderRadius={'10px'}
                                                                        >
                                                                            <Image
                                                                                src={boadr_thumbnail_path || ''}
                                                                                borderRadius={'10px'}
                                                                                position="absolute"
                                                                                top="50%"
                                                                                left="50%"
                                                                                transform="translate(-50%, -50%)"
                                                                                minWidth="100%"
                                                                                minHeight="100%"
                                                                                objectFit={'cover'}
                                                                            />
                                                                        </Box>
                                                                    </Box>

                                                                    <Flex flexDir={'column'} pl={'32px'}>
                                                                        <Text
                                                                            fontWeight={'500'}
                                                                            fontSize={'12px'}
                                                                            lineHeight={'18px'}
                                                                            letterSpacing="-0.03em"
                                                                            color={'#8C919F'}
                                                                        >
                                                                            PRESS
                                                                        </Text>
                                                                        <Text
                                                                            fontWeight={'500'}
                                                                            fontSize={'16px'}
                                                                            lineHeight={'24px'}
                                                                            letterSpacing="-0.03em"
                                                                            pt={'8px'}
                                                                            color={'#131313'}
                                                                        >
                                                                            {title || '-'}
                                                                        </Text>
                                                                    </Flex>
                                                                </Flex>
                                                            </Link>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </Flex>
                                    </Box>
                                }
                            >
                                <Tween from={{ y: -12 }} to={{ y: 0 }} duration={0.5} />
                            </Timeline>
                        </Box>
                    </ChakraProvider>
                )}
            </Flex>
        </React.Fragment>
    );
};

export default Topbar;


import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Controls, PlayState, Timeline, Tween } from 'react-gsap';

interface InvestorsProps {
    section03Ref: React.MutableRefObject<any>;
}
function Investors(props : InvestorsProps) {
  const {section03Ref} = props;
  return (
    <div className="section03" ref={section03Ref}>
        <div className="section-title">
            <div className="title-text">
                <p className="text-p1">INVESTORS</p>
            </div>
        </div>
        <div className="section-content">
            <ul>
                <li><img src={require("../../../assets/images/main/investors01.png")} /></li>
                <li><img src={require("../../../assets/images/main/investors02.png")} /></li>
                <li><img src={require("../../../assets/images/main/investors03.png")} /></li>
                <li><img src={require("../../../assets/images/main/investors04.png")} /></li>
                <li><img src={require("../../../assets/images/main/investors05.png")} /></li>
                <li><img src={require("../../../assets/images/main/investors06.png")} /></li>
                <li><img src={require("../../../assets/images/main/investors07.png")} /></li>
                <li><img src={require("../../../assets/images/main/investors08.png")} /></li>
            </ul>
        </div>
    </div>
  )
}


export default Investors;